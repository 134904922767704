<template>
  <div>
    <b-alert variant="warning" show>
      <b-icon icon="exclamation-triangle" class="mr-2"></b-icon>
      <strong>Vista en progreso</strong>
    </b-alert>
  </div>
</template>

<script>
export default {
  name: 'ReportesView'
}
</script>

<style lang="scss" scoped></style>
